import logo from './logo.svg';
import './App.css';
import Spline from '@splinetool/react-spline';

function App() {

  return (
    <div className="flex row" >

      <div style={{width: '60%', height: '100vh', backgroundColor: 'black', position: 'relative'}}>
        <h3 className="abs center">coming soon...</h3>
        <Spline scene="https://prod.spline.design/dB8Tbr76wo6QbHhA/scene.splinecode" />
      </div>

      <div className="container border left" style={{width: '40%'}}>
        <div className="flex col content border bottom"></div>
        <div className="flex col content">
          <h1>quantum</h1>
          <h2>staking</h2>
        </div>

        <div className="border top content">
          <p style={{maxWidth: '25rem'}}> 
          A TARGET-FOCUSED SYSTEM FOR FINDING DECENTRALIZED COUNTERPARTIES, SOLVING CHALLENGES, MANAGING INFORMATION FLOWS, AND EXECUTING INTER-CHAIN TRANSACTIONS EFFICIENTLY
          </p>

          <div className="button">
            <h4>coming soon...</h4>
          </div>

        </div>

      </div>

    </div>
  );
}

export default App;
//          <Spline scene="https://prod.spline.design/dB8Tbr76wo6QbHhA/scene.splinecode" />
